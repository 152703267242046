// src/AdminPrivacyPolicy.js
import React from "react";
import "../css/PrivacyPolicy.css"; // Keep using the same CSS file

const AdminPrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy for Dodiyala - Patel Samaj Admin App</h1>
      <p className="intro-text">
        At Dodiyala - Patel Samaj, we are committed to ensuring the privacy and
        security of the data managed by our <strong>Admin App</strong>. This
        Privacy Policy outlines how we collect, use, and protect the information
        gathered and processed by the Admin App. Please review this policy
        carefully.
      </p>

      <div className="section">
        <h2>1. App Purpose</h2>
        <p>
          The Dodiyala - Patel Samaj Admin App is used by authorized
          administrators to manage the content and data displayed in the
          Dodiyala - Patel Samaj community app. This includes managing user
          profiles, village photos, and other relevant community information.
        </p>
      </div>

      <div className="section">
        <h2>2. Data Collection</h2>
        <p>
          The Admin App collects and manages the following types of data to
          ensure the proper functioning of the Dodiyala - Patel Samaj
          application:
        </p>
        <ul>
          <li>
            <strong>User Profiles:</strong> This includes names, professions,
            and photos of community members.
          </li>
          <li>
            <strong>Village Content:</strong> Photos and other media related to
            the village community.
          </li>
          <li>
            <strong>Administrator Credentials:</strong> We collect login
            credentials for authorized admin users to ensure secure access to
            the app’s data management system.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>3. Data Usage</h2>
        <p>
          The data collected by the Admin App is used solely for the purpose of:
        </p>
        <ul>
          <li>
            Managing user profiles and content for display in the Dodiyala -
            Patel Samaj app.
          </li>
          <li>
            Ensuring that community-related information is accurately and
            securely updated.
          </li>
        </ul>
        <p>
          We do not use the data collected for any other purposes, including
          marketing, analytics, or third-party advertising.
        </p>
      </div>

      <div className="section">
        <h2>4. Third-Party Services</h2>
        <p>
          The Admin App does not integrate with any third-party services or
          share data with external entities.
        </p>
      </div>

      <div className="section">
        <h2>5. Data Security</h2>
        <p>
          We take the security of your data seriously and use standard
          encryption methods to protect it during storage and transmission.
          Access to the Admin App is restricted to authorized users, and we take
          precautions to ensure that only those with proper credentials can
          access the app's features.
        </p>
        <p>
          However, please note that no method of transmission over the internet
          or electronic storage is completely secure, and we cannot guarantee
          absolute security.
        </p>
      </div>

      <div className="section">
        <h2>6. User Rights</h2>
        <p>
          As the admin, you have control over the data that is managed through
          the app. If you need to correct or delete data, this can be done
          through the Admin App’s interface. If any further assistance is
          needed, you may contact us directly.
        </p>
      </div>

      <div className="section">
        <h2>7. Children’s Privacy</h2>
        <p>
          The Admin App is not intended for use by individuals under the age of
          18. If it comes to our attention that an unauthorized user under 18
          has accessed the Admin App, we will take steps to revoke access
          immediately.
        </p>
      </div>

      <div className="section">
        <h2>8. Contact Information</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or
          need assistance with data management within the Admin App, please
          contact us at:
          <strong> info@asquaretechlab.com</strong>
        </p>
      </div>

      <div className="section">
        <h2>9. Country of Operation</h2>
        <p>
          The Dodiyala - Patel Samaj Admin App operates in Rajkot, India, and is
          intended for use by authorized administrators managing the community
          app’s content.
        </p>
      </div>

      <div className="section">
        <h2>10. Changes to this Privacy Policy</h2>
        <p>
          We may update this Privacy Policy periodically. Any changes will be
          posted on this page, and we encourage administrators to review this
          policy regularly. This policy is effective as of [Insert Date].
        </p>
      </div>
    </div>
  );
};

export default AdminPrivacyPolicy;
