// src/PrivacyPolicy.js
import React from "react";
import "../css/PrivacyPolicy.css"; // Import the CSS file

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy for Dodiyala - Patel Samaj</h1>
      <p className="intro-text">
        At Dodiyala - Patel Samaj, we prioritize the privacy of our users. This
        Privacy Policy outlines how we handle your personal information when
        using the Dodiyala - Patel Samaj mobile application. Please read this
        document carefully to understand our practices.
      </p>
      <div className="section">
        <h2>1. App Purpose</h2>
        <p>
          The Dodiyala - Patel Samaj app is designed for the community members
          of Dodiyala village. It helps the members of the Patel Samaj community
          to connect, know each other, and stay updated about their village
          through the app. Users can view village photos and profiles of
          community members, including their professions.
        </p>
      </div>
      <div className="section">
        <h2>2. Data Collection</h2>
        <p>
          We do not collect any personal data directly from users of the
          Dodiyala - Patel Samaj app. All the information displayed in the app
          is collected and managed by our Admin App, and it is used strictly for
          the purposes of this community application.
        </p>
      </div>
      <div className="section">
        <h2>3. Data Usage</h2>
        <p>
          Although we do not collect any data directly from app users, the data
          displayed in the app (e.g., profiles, photos, and profession details)
          is managed and uploaded by the admin. We do not store or use any
          personal information other than what is necessary to present this
          content within the app. We do not use any collected data for
          analytics, marketing, or any other purposes.
        </p>
      </div>
      <div className="section">
        <h2>4. Third-Party Services</h2>
        <p>
          Dodiyala - Patel Samaj does not use any third-party services such as
          analytics, ads, or external APIs.
        </p>
      </div>
      <div className="section">
        <h2>5. Data Security</h2>
        <p>
          We use standard encryption methods to ensure the data displayed in the
          Dodiyala - Patel Samaj app is secure and protected from unauthorized
          access. However, please note that no method of transmission over the
          internet or method of electronic storage is 100% secure, and we cannot
          guarantee its absolute security.
        </p>
      </div>
      <div className="section">
        <h2>6. Children’s Privacy</h2>
        <p>
          Dodiyala - Patel Samaj is intended for individuals aged 18 and above.
          We do not knowingly target or provide services to children under 18.
          If we discover that we have unintentionally gathered personal data
          from individuals under this age, we will take immediate steps to
          delete such information.
        </p>
      </div>
      <div className="section">
        <h2>7. Your Data Rights</h2>
        <p>
          Since we do not collect any personal data through the app, there are
          no data rights in terms of access, deletion, or changes related to
          user data. All data displayed in the app is managed by the admin and
          only intended for community use.
        </p>
      </div>
      <div className="section">
        <h2>8. Contact Information</h2>
        <p>
          If you have any questions about this Privacy Policy or require further
          information about our data practices, you can contact us via email at:
          <strong> info@asquaretechlab.com</strong>
        </p>
      </div>
      <div className="section">
        <h2>9. Country of Operation</h2>
        <p>
          Dodiyala - Patel Samaj operates and is intended for users located in
          Rajkot, India.
        </p>
      </div>
      <div className="section">
        <h2>10. Changes to this Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. Any changes will
          be posted on this page, and we encourage you to review this policy
          periodically. This policy is effective as of [Insert Date].
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
