import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "../Style/Gallery.css";
import { useDispatch, useSelector } from "react-redux";
import { getGalleries } from "../Store/slice/samajSlice";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useState } from "react";

// export function CustomLightbox({
//   images,
//   photoIndex,
//   onClose,
//   onPrev,
//   onNext,
// }) {
//   return (
//     <div
//       style={{
//         position: "fixed",
//         top: 0,
//         left: 0,
//         width: "100vw",
//         height: "100vh",
//         backgroundColor: "rgba(0, 0, 0, 0.9)",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         zIndex: 1000,
//       }}
//     >
//       <button
//         onClick={onClose}
//         style={{ position: "absolute", top: 20, right: 20, color: "#fff" }}
//       >
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           x="0px"
//           y="0px"
//           width="30"
//           height="30"
//           viewBox="0 0 30 30"
//         >
//           <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"></path>
//         </svg>
//       </button>
//       <button
//         onClick={onPrev}
//         style={{ position: "absolute", left: 20, color: "black" }}
//       >
//         Prev
//       </button>
//       <img
//         src={images[photoIndex]}
//         alt="Gallery Lightbox"
//         crossOrigin="anonymous"
//         style={{ maxWidth: "90%", maxHeight: "90%" }}
//       />
//       <button
//         onClick={onNext}
//         style={{ position: "absolute", right: 20, color: "black" }}
//       >
//         {`>`}
//       </button>
//     </div>
//   );
// }

function GalleryImages() {
  const { imgurlId } = useParams();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  console.log("photoIndex :>> ", photoIndex);

  const galleryData = useSelector((state) => state.samaj.galleryData);
  const Gallery = galleryData?.data?.data?.find((p) => p._id === imgurlId);

  useEffect(() => {
    // Fetch galleries if galleryData is empty
    if (!galleryData || galleryData.length === 0) {
      dispatch(getGalleries());
    }
  }, [dispatch, galleryData]);

  // Check if Gallery exists to avoid accessing properties on undefined
  if (!Gallery) {
    return <p>Gallery Images not found</p>;
  }

  const images = Gallery.images.map(
    (img) => `${galleryData.data.imageBaseUrl}${img.url}`
  );

  console.log("images :>> ", images[photoIndex]);

  return (
    <div className="container">
      <div className="gallery-details" style={{ margin: "50px 0px" }}>
        {/*<h1 className="text-black" style={{ textDecoration: "underline" }}>
          {Gallery.title}
        </h1> */}
        <h3
          className="text-black text-center mb-4"
          style={{ textDecoration: "underline" }}
        >
          {Gallery.title}
        </h3>
        <div className="d-flex justify-center flex-wrap align-center w-100">
          {Gallery.images?.map((img, index) => (
            <img
              key={index}
              src={
                img.url
                  ? `${galleryData.data.imageBaseUrl}${img.url}`
                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNNLEL-qmmLeFR1nxJuepFOgPYfnwHR56vcw&s"
              }
              alt={`img ${index + 1}`}
              crossOrigin="anonymous"
              style={{
                width: "15rem",
                margin: "5px 5px",
                objectFit: "contain",
                cursor: "pointer",
              }}
              onClick={() => {
                setPhotoIndex(index);
                setIsOpen(true);
              }}
            />
          ))}
        </div>

        {/* {isOpen && (
          <CustomLightbox
            images={images}
            photoIndex={photoIndex}
            onClose={() => setIsOpen(false)}
            onPrev={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onNext={() => setPhotoIndex((photoIndex + 1) % images.length)}
          />
        )} */}

        {isOpen && images[photoIndex] && (
          <Lightbox
            mainSrc={images[photoIndex]}  
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </div>
    </div>
  );
}

export default GalleryImages;

// import React, { useEffect } from "react";
// import { useParams } from "react-router-dom";
// import "../Style/Gallery.css";
// import { useDispatch, useSelector } from "react-redux";
// import { getGalleries } from "../Store/slice/samajSlice";

// function GalleryImages() {
//   const { imgurlId } = useParams();
//   const dispatch = useDispatch();

//   // console.log("imgurlId :>> ", imgurlId);

//   const galleryData = useSelector((state) => state.samaj.galleryData);
//   const Gallery = galleryData?.data?.data.find((p) => p._id === imgurlId);

//   console.log("Gallery :>> ", Gallery.images);

//   useEffect(() => {
//     if (!galleryData || galleryData.length === 0) {
//       dispatch(getGalleries());
//     }
//   }, [dispatch, galleryData]);

//   if (!Gallery) return <p>Gallery Images not found</p>;

//   return (
//     <div className="container">
//       <div className="galler-details" style={{ margin: "50px 0px" }}>
//         <h1 className="text-black" style={{ textDecoration: "underline" }}>
//           {Gallery.title}
//         </h1>
//         <div className="d-flex justify-center flex-wrap align-center w-100">
//           {/* <div style={{ width: "200px", height: "200px" }}> */}
//             {Gallery.images?.map((img, index) => (
//               <img
//                 key={index}
//                 src={
//                   `${galleryData.data.imageBaseUrl}${img.url}`
//                     ? `${galleryData.data.imageBaseUrl}${img.url}`
//                     : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNNLEL-qmmLeFR1nxJuepFOgPYfnwHR56vcw&s"
//                 }
//                 alt={`img ${index + 1}`}
//                 crossOrigin="anonymous"
//                 style={{
//                   width: "15rem",
//                   margin: "5px 5px",
//                   objectFit: "contain",
//                 }}
//               />
//             ))}
//           {/* </div> */}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default GalleryImages;
