import ContactForm from "./Components/ContactForm";
import BookingForm from "./Components/BookingForm";
import Gallery from "./Components/Gallery";
import GalleryImages from "./Components/GalleryImages";
import Header from "./Components/Header";
import HeroSection from "./Components/HeroSection";
import CommunityForm from "./Components/CommunityForm";
import Footer from "./Components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./Components/About";
import Trustee from "./Components/Trustee";
import Rules from "./Components/Rules";
import "./App.css";
import Event from "./Components/Event";
import { ToastContainer } from "react-toastify";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import AdminPrivacyPolicy from "./Components/AdminPrivacyPolicy";
import { useEffect, useState } from "react";

function App() {
  const [loading, setLoading] = useState(true);
  const [blinkLogo, setBlinkLogo] = useState(true);

  useEffect(() => {
    // Simulate a network request or page load delay
    const timer = setTimeout(() => {
      setLoading(false);
      setBlinkLogo(false);
    }, 2000); // Adjust this duration as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            src="assets/samaj-logo.png"
            alt="Loading..."
            height={150}
            className="blinking"
          />
        </div>
      ) : (
        <Router>
          <Header />

          <ToastContainer />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <HeroSection />
                  {/* <About /> */}
                  {/* <Rules /> */}
                  <Event />
                  <Trustee />
                  <Gallery />
                  {/* <CommunityForm /> */}
                </>
              }
            />
            <Route path="/gallery/:imgurlId" element={<GalleryImages />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/admin-privacy-policy"
              element={<AdminPrivacyPolicy />}
            />
            {/* <Route path="/booking" element={<BookingForm />} /> */}
          </Routes>
          <Footer />
        </Router>
      )}
    </>
  );
}

export default App;
