import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBTypography,
  MDBCardText,
  MDBBtn,
} from "mdb-react-ui-kit";
import { getTrustee } from "../Store/slice/samajSlice";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../Style/Trustee.css";

const Trustee = () => {
  const dispatch = useDispatch();
  const { trusteeData, loading } = useSelector(
    (state) => state.samaj || { trusteeData: [], loading: false }
  );
  const sliderRef = useRef(null);

  // console.log('trusteeData :>> ', trusteeData);

  useEffect(() => {
    if (!loading && trusteeData.length === 0) {
      dispatch(getTrustee());
    }

    const handleScroll = () => {
      const elements = document.querySelectorAll(".custom-card");
      elements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (elementTop < windowHeight) {
          element.classList.add("visible");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger once on mount to check initial visibility

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch, loading, trusteeData.length]);

  const settings = {
    dots: true,
    infinite: trusteeData?.data?.data.length > 1,
    speed: 500,
    slidesToShow:
      trusteeData?.data?.data.length === 3
        ? 3
        : 4 || trusteeData?.data?.data.length === 3
        ? 2
        : 4 || trusteeData?.data?.data.length === 1
        ? 1
        : 4,
    slidesToScroll: 1,
    autoplay: trusteeData?.data?.data.length > 1,
    autoplaySpeed: 3500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (!Array.isArray(trusteeData?.data?.data))
    return <div>Data not found </div>;

  return (
    <div id="trustee">
      <MDBContainer>
        <MDBRow>
          <MDBCol>
            <div className="admin">
              <div className="heading mb-0">
                <h3 className="mb-0 text-black">OFFICE BEARERS</h3>
              </div>
            </div>

            {/* <Slider ref={sliderRef} {...settings} className="py-3">
            </Slider> */}
           <div className="w-100 d-flex flex-row flex-wrap justify-content-center">
  {/* Render 'પ્રમુખ' designation items first in a single column */}
  {trusteeData.data.data
    .slice()
    .reverse()
    .filter((user) => user.designation === "પ્રમુખ")
    .map((user) => (
      <MDBCard
        key={user._id}
        className="custom-card p-0 col-12 mb-3" // Full width for 'પ્રમુખ' designation
        style={{ borderRadius: "15px", height: "auto" }}
      >
        <MDBCardBody className="text-center p-0">
          <div className="mt-3 mb-4 d-flex justify-content-center">
            <MDBCardImage
              src={
                `${trusteeData.data.imageBaseUrl}${user.profilePic.url}` ||
                "https://i0.wp.com/digitalhealthskills.com/wp-content/uploads/2022/11/3da39-no-user-image-icon-27.png?fit=500%2C500&ssl=1"
              }
              alt={user.name}
              crossOrigin="anonymous"
              className="rounded-circle"
              fluid
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
              }}
            />
          </div>
          <MDBTypography tag="h4" className="fs-6">
            {user.name}
          </MDBTypography>
          <MDBCardText className="text-muted mb-2">
            {user.designation}
            <br />
            <a href={`mailto:${user.email}`} style={{ color: "#757575" }}>
              {user.email}
            </a>
          </MDBCardText>
          <MDBCardText className="text-muted mb-3">
            {user.description}
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    ))}

  {/* Render other items in two columns */}
  {trusteeData.data.data
    .slice()
    .reverse()
    .filter((user) => user.designation !== "પ્રમુખ")
    .map((user) => (
      <MDBCard
        key={user._id}
        className="custom-card p-0 col-5 col-md-4 col-lg-3 mx-2 my-2" // Add padding for spacing
        style={{ borderRadius: "15px", height: "auto" }}
      >
        <MDBCardBody className="text-center p-0">
          <div className="mt-3 mb-4 d-flex justify-content-center">
            <MDBCardImage
              src={
                `${trusteeData.data.imageBaseUrl}${user.profilePic.url}` ||
                "https://i0.wp.com/digitalhealthskills.com/wp-content/uploads/2022/11/3da39-no-user-image-icon-27.png?fit=500%2C500&ssl=1"
              }
              alt={user.name}
              crossOrigin="anonymous"
              className="rounded-circle"
              fluid
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
              }}
            />
          </div>
          <MDBTypography tag="h4" style={{fontSize:"0.9rem"}}>
            {user.name}
          </MDBTypography>
          <MDBCardText className="text-muted mb-2">
            {user.designation}
            <br />
            <a href={`mailto:${user.email}`} style={{ color: "#757575" }}>
              {user.email}
            </a>
          </MDBCardText>
          <MDBCardText className="text-muted mb-3">
            {user.description}
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    ))}
</div>

          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default Trustee;
