import { fetchAPI } from "../Store/apiConfig";

export const GetHomepageData = async () => {
  return await fetchAPI("/home", {
    method: "GET",
  });
};

export const GetUpcomingEvents = async () => {
  return await fetchAPI("/upcoming-events", {
    method: "GET",
  });
};

export const GetTrustee = async () => {
  return await fetchAPI("/trustes", {
    method: "GET",
  });
};

export const CreateBooking = async (payload) => {
  return await fetchAPI(`/booking`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const GetFuctionType = async () => {
  return await fetchAPI("/function", {
    method: "GET",
  });
};


export const GetGalleries = async () => {
  return await fetchAPI("/galleries",{
    method:"GET",
  });
}

// export const CreateBooking = async (payload) => {
//   try {
//     const response = await fetch("http://localhost:3000/api/booking", {
//       method: "POST",
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(payload),
//     });
//     if (!response.ok) {
//       const errorData = await response.json(); // Assuming the server sends back a JSON with error details
//       throw new Error(errorData.message || 'Failed to create booking');
//     }
//     return await response.json();
//   } catch (error) {
//     // Better error structure
//     throw {
//       message: error.message,
//       response: error.response ? await error.response.json() : undefined
//     };
//   }
// };
