import React from "react";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import "../Style/Footer.css";
import { Link } from "react-router-dom";

function CommunityForm() {
  const isMobile = window.innerWidth <= 1048;

  return (
    <div className="" style={{ background: "#D8E6F3" }}>
      <div className="ftr-container">
        {/* Footer Widgets
					============================================= */}
        <div className="">
          <div className="">
            <div className="widget clearfix" id="ftr-widget-left">
              {/* <a href="http://localhost:3000/">
                <img
                  alt="Footer Logo"
                  src="https://www.khodaldhamtrust.org/upload_data/general/5e3167aeafc98_khodaldham_logo_english.svg
"
                  style={{ position: "relative", top: "0px" }}
                />
              </a> */}
              <div>
                <h4 className="mt-2">Contact :</h4>
                <div
                  className="kd-footer-line"
                  style={{ margin: " 0", lineHeight: "10px" }}
                />
                <div style={{ marginTop: "20px" }}>
                  <p className="mb-1">
                    <strong>Phone No.</strong> :{" "}
                    <a href="tel:+91 9898050550"> +91 9898050550</a>
                  </p>
                  <p className="mb-1">
                    <strong>Phone No.</strong> :{" "}
                    <a href="tel:+91 9979266438"> +91 9979266438</a>
                  </p>
                  <p className="mb-1">
                    <strong>Phone No.</strong> :{" "}
                    <a href="tel:+91 9998887742"> +91 9998887742</a>
                  </p>
                  <p className="mb-1">
                    <strong>Phone No.</strong> :{" "}
                    <a href="tel:+91 9909624145"> +91 9909624145</a>
                  </p>
                  <br />
                  <p style={{ marginTop: "-20px" }}>
                    <strong> Email :</strong>

                    <a
                      href="mailto:leuvapatelsamajdodiyala@gmail.com"
                      // target="_blank"
                      // rel="noopener"
                      // style={{ marginLeft: "5px" }}
                    >
                      leuvapatelsamajdodiyala@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="footer-img" style={{width:"150px", height:"150px"}}>
          <img
            src="assets/samaj-logo.jpg"
            alt="patel-samaj"
          />
        </div> */}
        {!isMobile && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src="assets/samaj-logo.png"
              alt="patel-samaj"
              style={{ height: "150px" }}
            />
          </div>
        )}

        <div>
          <div>
            <div className="kd-footer-contact">
              <h4
                className="mt-2"
                style={{
                  textAlign: window.innerWidth <= 576 ? "center" : "left", // 576px is Bootstrap's sm breakpoint
                }}
              >
                Address :
              </h4>
              <div
                className="kd-footer-line mb-4"
                style={{ margin: "0px 0 10px 0" }}
              />
              <div>
                <p>
                  <strong>શ્રી લેઉવા પટેલ સમાજ - ડોડીયાળા</strong>
                </p>
                <p>
                  શ્રી ધરણીધર હનુમાનજી મંદિરની બાજુમાં, સાણથલી રોડ, મું.
                  ડોડીયાળા, તા. જસદણ, જી. રાજકોટ
                </p>
              </div>

              {/* <div className="copyright-soc-icons fright clearfix social-links"> */}
              <div className="social-links">
                <a
                  href="https://www.facebook.com/share/E1nwb9mopJN3NDSi/?mibextid=qi2Omg"
                  target="_blank"
                  rel="noreferrer"
                  className="social-icon si-borderless nobottommargin"
                  title="facebook link"
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "0px",
                  }}
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://www.instagram.com/shreeleuvapatelsamajdodiyala"
                  target="_blank"
                  rel="noopener"
                  className="social-icon si-borderless nobottommargin"
                  title="instagram link"
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "0px",
                  }}
                >
                  <FaInstagram />
                </a>
                {/* <a
                  href="https://twitter.com/khodaldhamtrust/"
                  // target="_blank"
                  rel="noopener"
                  className="social-icon si-borderless nobottommargin"
                  title="twitter link"
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "0px",
                  }}
                >
                  <FaTwitter />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCcqK8-hbEl8vJMGtmQSSFfw"
                  // target="_blank"
                  rel="noopener"
                  className="social-icon si-borderless nobottommargin"
                  title="youtube link"
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "0px",
                  }}
                >
                  <FaYoutube />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="copyrights">
        <div className="footer-bottom">
          <div> © Copyright 2023 Shree Leuva Patel Samaj Trust - Dodiyala.</div>
          <div>
            <Link to="/privacy-policy" className="text-black">
              Privacy-Policy (User)
            </Link>{" "}
            &nbsp; | &nbsp;
            <Link to="/admin-privacy-policy" className="text-black">
              Privacy-Policy (Admin)
            </Link>
          </div>
          <div>
            {" "}
            Designed & Developed by :
            <a
              href="https://asquaretechlab.com/"
              style={{ color: "black", marginLeft: "5px" }}
            >
              Asqure Tech Lab LLP
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunityForm;
