import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetHomepageData,
  GetTrustee,
  GetUpcomingEvents,
  CreateBooking,
  GetFuctionType,
  GetGalleries,
} from "../../API/samajAPI";

const initialState = {
  homeData: [],
  upcomingEventsData: [],
  trusteeData: [],
  items: [],
  galleryData: [],
  functionTypeData: [],
  loading: false,
  error: null,
};

export const getHomepageData = createAsyncThunk("samaj/home", async () => {
  const response = await GetHomepageData();
    // console.log("GetHomepageData :>> ", response);
  return response;
});

export const getUpcomingEvents = createAsyncThunk("samaj/event", async () => {
  const response = await GetUpcomingEvents();
  // console.log("GetUpcomingEvents :>> ", response.data);
  return response;
});

export const getTrustee = createAsyncThunk("samaj/trustee", async () => {
  const response = await GetTrustee();
  // console.log("trusteedata >>>", response.data);
  return response;
});

export const postBooking = createAsyncThunk(
  'samaj/postBooking',
  async (booking, { rejectWithValue }) => {
    try {
      const response = await CreateBooking(booking);
      // console.log('CreateBooking :>> ', response);
      return response;
    } catch (error) {
      // Log here to see what error actually looks like
      console.error("Error in postBooking:", error);
      return rejectWithValue(error.message || "Unknown error");
    }
  }
);

export const getFunctionType = createAsyncThunk(
  'samaj/getFunctionType',
  async () => {
   const response = await GetFuctionType();
  //  console.log('GetFuctionType :>> ', response.data);
   return response
});

export const getGalleries = createAsyncThunk(
  'samaj/getGalleries',
  async (_, {rejectWithValue}) => {
    try {
      const response = await GetGalleries();
      // console.log('GetGalleries :>> ', response.data);
      return response;
    } catch (error) {
      console.error('API error :>> ', error);
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
)


export const homepageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHomepageData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHomepageData.fulfilled, (state, action) => {
        state.loading = false;
        state.homeData = action.payload;
      })
      .addCase(getHomepageData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getUpcomingEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUpcomingEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.upcomingEventsData = action.payload;
      })
      .addCase(getUpcomingEvents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getTrustee.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTrustee.fulfilled, (state, action) => {
        state.loading = false;
        state.trusteeData = action.payload;
      })
      .addCase(getTrustee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(postBooking.pending, (state) => {
        state.loading = true;
      })
      .addCase(postBooking.fulfilled, (state, action) => {
        state.items.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(postBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getFunctionType.pending, (state) => {
        state.pending = true;
      })
      .addCase(getFunctionType.fulfilled, (state, action) => {
        state.loading = false;
        state.functionTypeData = action.payload;
      })
      .addCase(getFunctionType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getGalleries.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGalleries.fulfilled, (state, acction) => {
        state.loading = false;
        state.galleryData = acction.payload;
      })
      .addCase(getGalleries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const { reducer } = homepageSlice;

export default homepageSlice.reducer;
