import React, { useEffect } from "react";
import "../Style/Gallery.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGalleries } from "../Store/slice/samajSlice";

const Gallery = () => {
  const dispatch = useDispatch();

  const { galleryData, loading } = useSelector(
    (state) => state.samaj || { galleryData: [], loading: false }
  );

  // console.log('galleryData :>> ', galleryData);

  useEffect(() => {
    if (!loading && galleryData.length === 0) {
      dispatch(getGalleries());
    }
  }, [dispatch, loading, galleryData.length]);

  if (!Array.isArray(galleryData?.data?.data)) return <div>Data not found</div>;

  return (
    <div id="gallery">
      <h1 className="text-black mt-4">GALLERY</h1>
      <div className="gallery">
        {galleryData?.data?.data.map((imgurl, index) => (
          <Link
            key={imgurl._id}
            to={`/gallery/${imgurl._id}`}
            // style={{ margin: "10px" }}
          >
            <div key={index} className="gallery-item">
              <div className="img-cntnr">
                <img
                  src={
                    `${galleryData.data.imageBaseUrl}${imgurl.thumbnailImage.url}`
                      ? `${galleryData.data.imageBaseUrl}${imgurl.thumbnailImage.url}`
                      : " "
                  }
                  crossOrigin="anonymous"
                  className="gallery-image"
                  alt={imgurl.thumbnailImage.name}
                />
              </div>
              <div className="image-title">{imgurl.title}</div>
            </div>
          </Link>
        ))}
      </div>

      <section className="kd-contact-wrapper">
      <div className="container">
        <div className="kd-contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19356.43626719406!2d70.9701378!3d21.8854123!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39584327ccf7b42f%3A0x1f1db81bc11e3e20!2sSHREE%20LEUVA%20PATEL%20SAMAJ%20-%20DODIYALA!5e1!3m2!1sen!2sin!4v1723892004046!5m2!1sen!2sin"
            title="shree-leuva-patel-samaj"
            width="600"
            height="450"
            style={{border:0}}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3704.74129220815!2d70.68886826956914!3d21.790271472583235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39589ff11ef68687%3A0xa31dea34ebd2624b!2sShree%20Khodaldham%20Temple!5e0!3m2!1sen!2sin!4v1578742246937!5m2!1sen!2sin"
            // style={{ border: 0 , width: "auto" , height: "auto"}}
            allowFullScreen=""
          /> */}
        </div>
      </div>
    </section>
    </div>
  );
};

export default Gallery;
