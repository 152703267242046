// import React, { useEffect, useState } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import "../Style/HeroSection.css";
// import { useDispatch, useSelector } from "react-redux";
// import { getHomepageData } from "../Store/slice/samajSlice";

// export default function Herosection() {
//   var settings = {
//     dots: false,
//     infinite: true,
//     speed: 1500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2500,
//     fade: true,
//   };

//   const dispatch = useDispatch();

//   const { homeData, loading } = useSelector(
//     (state) => state.samaj || { homeData: [], loading: false }
//   );

//   console.log('homeData :>> ', homeData.data.data[0].title);

//   useEffect(() => {
//     if (!loading && homeData.length === 0) {
//       dispatch(getHomepageData());
//     }
//   }, [dispatch, loading, homeData.length]);

//   if (loading) return <div>Loading...</div>;
//   if (!Array.isArray(homeData.data)) return <div>Data not found</div>;

//   return (
//     <div id="hero" className="slider">
//       <>
//         <Slider {...settings}>
//             {homeData.data.data.map((home) => (
//               <div key={home._id}>
//                 <img
//                   src="https://www.khodaldhamtrust.org/upload_data/general/n_resize/1280px/5e3301178a8a7_5e14cc294f3ff_about-banner.jpg"
//                   alt="asd"
//                 />
//                 <div className="text-content text-black">
//                   <h1>{home.title}</h1>
//                   <p>{home.description}</p>
//                   <button className="know-more">Know More</button>
//                 </div>
//               </div>
//             ))}
//         </Slider>

//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           viewBox="0 0 1382 58"
//           width="100%"
//           height="60"
//           preserveAspectRatio="none"
//           style={{
//             position: "absolute",
//             bottom: "0",
//             left: "0",
//             zIndex: "1",
//           }}
//         >
//           <path
//             style={{ fill: "#fff" }}
//             d="M1.52.62s802.13,127,1380,0v56H.51Z"
//           ></path>
//         </svg>
//       </>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getHomepageData } from "../Store/slice/samajSlice";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Style/HeroSection.css";

export default function HeroSection() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
  };

  const dispatch = useDispatch();
  const { homeData = [], loading } = useSelector(
    (state) => state.samaj || { homeData: [], loading: false }
  );

  // console.log(homeData.data);

  useEffect(() => {
    if (!loading && homeData.length === 0) {
      dispatch(getHomepageData());
    }
  }, [dispatch, loading, homeData.length]);

  useEffect(() => {
    const textContent = document.querySelector(".text-content");
    if (textContent) {
      textContent.classList.add("animate"); // Add 'animate' class to trigger the animation
    }
  }, []);

  // const [imageUrl, setImageUrl] = useState(null);

  // useEffect(() => {
  //   fetch('https://api.dodiyala.leuvapatelsamaj.com/uploads/home-images/1727864787638-samaj-home-transformed.jpeg')
  //     .then(response => response.blob())
  //     .then(blob => {
  //       const objectURL = URL.createObjectURL(blob);
  //       setImageUrl(objectURL);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching image:', error);
  //     });
  // }, []);

  if (loading) {
    return <div></div>;
  }

  if (!Array.isArray(homeData?.data?.data)) {
    return <div>Error: Data is not in expected format</div>;
  }

  return (
    <div id="hero" className="slider">
      <Slider {...settings}>
        {homeData.data.data.map((home) => (
          <div key={home._id}>
            {loading ? (
              <img
                // src={`https://api.dodiyala.leuvapatelsamaj.com/${home.image.url}` ? `https://api.dodiyala.leuvapatelsamaj.com/${home.image.url}` : "https://www.khodaldhamtrust.org/upload_data/homepage/slider/n_resize/1280px/sli_201015_042228_5f8829ec76f43.jpg"}
                // src={`${homeData.data.imageBaseUrl}${home.image.url}`}
                src="assets/samaj-home.jpeg"
                alt={home.title}
                className="slider-image"
                loading="false"
                crossOrigin="anonymous" 
              />
            ) : (
              <img
              // src={`https://api.dodiyala.leuvapatelsamaj.com/${home.image.url}` ? `https://api.dodiyala.leuvapatelsamaj.com/${home.image.url}` : "https://www.khodaldhamtrust.org/upload_data/homepage/slider/n_resize/1280px/sli_201015_042228_5f8829ec76f43.jpg"}
              // src={`${homeData.data.imageBaseUrl}${home.image.url}`}
              src="assets/samaj-home.jpeg"
              alt={home.title}
              className="slider-image"
              loading="false"
              crossOrigin="anonymous" 
            />
            )}
            {/* <div className="text-content text-black">
              <h1 className="animated-item">{home.title}</h1>
              <p className="animated-item">{home.description}</p>
              <button className="know-more animated-item">Know More</button>
            </div> */}
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1382 58"
              width="100%"
              height="60"
              preserveAspectRatio="none"
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                zIndex: "1",
              }}
            >
              <path
                style={{ fill: "#fff" }}
                d="M1.52.62s802.13,127,1380,0v56H.51Z"
              ></path>
            </svg> */}
          </div>
        ))}
      </Slider>
    </div>
  );
}
